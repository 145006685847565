<template>
  <div class="flex">
    <div :class="'flex flex-1 flex-col print:max-w-[unset]' + (!isPreviewMode ? 'max-w-[calc(100%-400px)]' : '')">
      <!-- TOPBAR -->
      <div class="sticky top-0 z-10 bg-white shadow-lg print:hidden" v-if="!isPreviewMode">
        <div class="flex h-16 w-full items-center space-x-6 border-b px-12">
          <button
            class="rounded p-2 hover:bg-[#00d9b8]/20"
            @click="$root.$router.push('/client-edition/templates')"
            aria-label="Return to templates"
          >
            <ui-asset :width="30" :height="30" name="icon_home"></ui-asset>
          </button>
          <label class="flex flex-col">
            <div class="w-40 text-xs font-semibold text-[#00d9b8]">Template</div>
            <select class="flex rounded border-transparent bg-transparent py-0 pl-0" v-model="templateName">
              <option v-for="v in templates" :value="v">{{ v }}</option>
            </select>
            <!-- <input type="url" v-model="dataReportId" /> -->
          </label>
          <label class="relative flex flex-col">
            <a
              class="absolute -top-1 right-1 flex rounded bg-transparent px-2 py-1 hover:bg-[#00d9b8]/10"
              :href="studioURL"
              target="_blank"
              aria-label="Open Apollo Studio"
            >
              <ui-asset :width="14" :height="14" name="icon_link"></ui-asset>
            </a>
            <div class="w-40 text-xs font-semibold text-[#00d9b8]">Data Report</div>
            <select class="flex rounded border-transparent bg-transparent py-0 pl-0" v-model="dataReportId">
              <option v-for="dr in dataReports" :value="dr.id">{{ dr.name }}</option>
            </select>
            <!-- <input type="url" v-model="dataReportId" /> -->
          </label>
          <label class="relative flex flex-col">
            <a
              class="absolute -top-1 right-1 flex rounded bg-transparent px-2 py-1 hover:bg-[#00d9b8]/10"
              @click="dqcReportId = ''"
            >
              <ui-asset :width="14" :height="14" name="icon_trash"></ui-asset>
            </a>
            <div class="w-40 text-xs font-semibold text-[#00d9b8]">DQC Report</div>
            <select class="flex rounded border-transparent bg-transparent py-0 pl-0" v-model="dqcReportId">
              <option v-for="dqcr in dqcReports || []" :value="dqcr.id">
                {{ dqcr.name }}
              </option>
            </select>

            <!-- <input type="url" v-model="dataReportId" /> -->
          </label>
          <div v-if="state === 'ready'" class="flex flex-1 justify-end gap-2">
            <button
              class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              @click="window.print()"
            >
              <ui-asset :width="20" :height="20" name="icon_printer"></ui-asset>
              <div class="hidden font-semibold 2xl:block">Print</div>
            </button>
            <button
              class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
              :class="{ 'cursor-not-allowed': store.undo.length === 1 }"
              :disabled="store.versions.at(-1)?.readOnly"
              @click="undo()"
            >
              <ui-asset :width="20" :height="20" name="icon_undo"></ui-asset>
              <div class="hidden font-semibold 2xl:block">Undo</div>
              <div
                class="absolute -right-1 -top-1 h-4 w-4 truncate rounded-full bg-[#00d9b8]/75 text-[10px] leading-[16px]"
                v-if="store.undo.length > 1"
              >
                {{ store.undo.length - 1 }}
              </div>
            </button>
            <button
              class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
              :class="{ 'cursor-not-allowed': store.redo.length === 0 }"
              @click="redo()"
              :disabled="store.redo.length === 0 || store.versions.at(-1)?.readOnly"
            >
              <ui-asset :width="20" :height="20" name="icon_redo"></ui-asset>
              <div class="hidden font-semibold 2xl:block">Redo</div>
              <div
                class="absolute -right-1 -top-1 h-4 w-4 truncate rounded-full bg-[#00d9b8]/75 text-[10px] leading-[16px]"
                v-if="store.redo.length"
              >
                {{ store.redo.length }}
              </div>
            </button>
            <button
              class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
              :disabled="store.versions.at(-1)?.readOnly"
              @click="saveVersion(dataReportId, +dqcReportId || null)"
            >
              <ui-asset :width="20" :height="20" name="icon_save"></ui-asset>
              <div class="hidden font-semibold 2xl:block">Save</div>
            </button>
            <button
              class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
              @click="store.versions.open = !store.versions.open"
            >
              <ui-asset :width="20" :height="20" name="icon_history"></ui-asset>
              <div class="hidden font-semibold 2xl:block">History</div>
              <!-- <div>{{ store.versions.length }}</div> -->
              <div class="fixed inset-0 bg-black/10" v-if="store.versions.open"></div>
              <div
                class="absolute right-0 top-0 translate-y-12 border bg-white shadow"
                @click.stop
                v-if="store.versions.open"
              >
                <div class="m-2 flex gap-2">
                  <button
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    @click="downloadVersion"
                  >
                    Download
                  </button>
                  <label
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                  >
                    <input type="file" class="hidden" accept="application/json" @input="uploadVersion" />
                    Upload
                  </label>
                </div>
                <div class="m-2 flex gap-2" v-for="version in store.versions">
                  <div class="flex w-24 truncate">{{ new Date(version.createdAt).format('MM/DD hh:mm') }}</div>
                  <div class="flex w-60 truncate">{{ version.user }}</div>
                  <button
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    @click="restoreVersion(version)"
                  >
                    Restore
                  </button>
                  <button
                    class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
                    @click="deleteVersion(version)"
                    :disabled="store.versions.length === 1"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </button>
            <button
              class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10 disabled:pointer-events-none disabled:opacity-40"
              @click="refresh"
            >
              <ui-asset :width="20" :height="20" name="icon_refresh"></ui-asset>
              <div class="hidden font-semibold 2xl:block">Refresh</div>
            </button>
          </div>
        </div>
        <div class="flex h-16 w-full items-center space-x-6 pl-[120px]">
          <builderui-variables-form
            :inputs="variableInputs"
            :missing-dependencies="missingDependencies"
            :inputData="inputData"
            :variables="variables"
            :update-variable="updateVariable"
          />
        </div>
      </div>

      <!-- PREVIEW -->
      <div v-html="'<style>' + css + '</style>'" v-for="(css, idx) in templateCss" :key="idx"></div>
      <!-- <div>{{ state }}</div> -->
      <div v-if="state === 'ready'" class="[font-size:--text_size]" :style="theme.variables">
        <div
          class="theme-all pdf isolate flex flex-wrap justify-center gap-8 p-8"
          :class="[theme.class, { blueprint: blueprint && tab !== 'theme' }]"
          :style="{ zoom }"
          :key="refreshKey"
        >
          <template v-for="(page, index) in template.nodes" :key="index">
            <builder
              data-builder
              class="pdf-page"
              :node="page"
              :data="dataReport"
              :layout="template"
              :store="store"
              :context="context"
              :path="[index]"
              :virtual="page.isVirtual"
              :isPreviewMode="isPreviewMode"
              @active="storeActive"
              @overflow="onOverflow"
            />
            <builder
              class="pdf-page"
              v-if="template.overflowPages?.[index]"
              :node="template.overflowPages?.[index]"
              :data="dataReport"
              :store="store"
              :layout="template"
              :context="context"
              :virtual="true"
              @active="storeActive"
              :path="[index]"
            ></builder>
          </template>
        </div>
      </div>
    </div>

    <!-- TABS -->
    <div
      class="sticky top-0 z-[9] flex h-screen min-w-[400px] max-w-[400px] flex-col gap-8 overflow-auto overscroll-y-contain bg-neutral-900 text-white print:hidden"
    >
      <!-- Helper - Nav Top Empty -->
      <!-- TODO: this never happens, looks like templates are initialised with the navTop form filled -->
      <div v-if="state === 'init'" class="flex flex-1 flex-col items-center justify-center">
        <div
          class="font-regular relative mb-4 block w-2/3 rounded-lg !bg-[#00d9b8]/75 p-4 text-base leading-5 text-white opacity-100"
        >
          Complete the Nav Top form before building a template.
        </div>
      </div>

      <!-- Loader -->
      <div v-if="state === 'loading'" class="flex flex-1 flex-col items-center justify-center">
        <div
          class="font-regular relative mb-4 block w-2/3 rounded-lg !bg-[#00d9b8]/75 p-4 text-base leading-5 text-white opacity-100"
        >
          Template initialization, please wait..
        </div>
      </div>

      <!-- Error -->
      <div v-if="state === 'error'" class="flex flex-1 flex-col items-center justify-center">
        <div
          class="font-regular relative mb-4 block w-2/3 rounded-lg bg-red-600 p-4 text-base leading-5 text-white opacity-100"
        >
          {{ errorMessage }}
          <br />
          <br />
          Please contact Impress support for assistance.
        </div>
      </div>

      <!-- Read Only Mode -->
      <div
        v-if="state === 'ready' && store.versions.at(-1)?.readOnly"
        class="flex flex-1 flex-col items-center justify-center"
      >
        <div
          class="font-regular relative mb-4 block w-2/3 rounded-lg !bg-[#f55a3a]/75 p-4 text-base leading-5 text-white opacity-100"
        >
          This Template is read-only
        </div>
      </div>

      <div
        v-if="state === 'ready' && !isPreviewMode && !store.versions.at(-1)?.readOnly"
        class="flex h-16 items-center border-b border-gray-500"
      >
        <button
          role="tab"
          class="h-full w-1/3 text-lg font-bold hover:bg-neutral-600"
          :class="tab == 'theme' ? '!bg-[#00d9b8]/75' : ''"
          @click="tab = 'theme'"
        >
          Theme
        </button>
        <button
          role="tab"
          class="h-full w-1/3 text-lg font-bold hover:bg-neutral-600"
          :class="tab == 'layout' ? '!bg-[#00d9b8]/75' : ''"
          @click="tab = 'layout'"
        >
          Layout
        </button>
        <button
          role="tab"
          class="h-full w-1/3 text-lg font-bold hover:bg-neutral-600"
          :class="tab == 'component' ? '!bg-[#00d9b8]/75' : ''"
          @click="tab = 'component'"
        >
          Component
        </button>
      </div>

      <builderui-theme-tab
        v-if="
          tab == 'theme' && template.theme && state === 'ready' && !isPreviewMode && !store.versions.at(-1)?.readOnly
        "
        :stylesheets="stylesheets"
        :store="store"
        v-model="template"
      ></builderui-theme-tab>
      <builderui-layout-tab
        v-if="tab == 'layout' && state === 'ready' && !isPreviewMode && !store.versions.at(-1)?.readOnly"
        :store="store"
        :zoom="zoom"
        :blueprint="blueprint"
        @update:zoom="zoom = $event"
        @update:storeActive="store.active = $event"
        @update:blueprint="blueprint = $event"
        v-model="template"
      ></builderui-layout-tab>
      <template v-if="tab == 'component' && state === 'ready' && !isPreviewMode && !store.versions.at(-1)?.readOnly">
        <builderui-component-tab
          :component-list="componentList"
          :data-report="dataReport"
          :stylesheets="stylesheets"
          :context="context"
          :store="store"
          @update-style="updateStyles"
          @active="storeActive"
          v-model="template"
        ></builderui-component-tab>
      </template>
    </div>
  </div>
</template>

<style>
.pdf-block {
  min-height: 10px;
}
</style>

<script setup lang="ts">
import { ref, onMounted, watch, computed, Ref, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import templateService from '@100-m/hauru/src/services/TemplateService'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'
import useStore from '../composables/store'
import useBuilderContext, { addDataReportTranslations } from '../composables/builderContext'
import useVersioning, { formatTemplateToStore } from '../composables/versioning'
import type { TemplateLayout } from '../builder'
import { getComponentVizType, checkCompatibility, useComponents } from '../composables/builderComponent'
import { getTemplateCss } from '../composables/theme'
import { migrateLayout } from '../lib/migrate'
import useLayout from '../composables/layout'

const stylesheets = ref<string[]>([])
const router = useRouter()
const route = useRoute()
function updateQuery(query: Record<string, string>) {
  router.push({ query: { ...route.query, ...query } })
}
Object.equal = (a, b) => {
  if (a === b) return true
  const ta = Object.prototype.toString.call(a)
  if (ta !== Object.prototype.toString.call(b)) return false
  if (!['[object Object]', '[object Array]'].includes(ta)) return a.toString() === b.toString()
  if (Object.keys(a).length !== Object.keys(b).length) return false
  return Object.keys(a).every(k => Object.equal(a[k], b[k]))
}
Object.traverse = (obj, fnLeaf, fnNode, path = [], root) => {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    return Object.keys(obj).forEach((k, i) => Object.traverse(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}
type State = 'init' | 'waiting_input' | 'loading' | 'error' | 'ready'
const state: Ref<State> = ref('init')
const isPreviewMode = ref(false)
const errorMessage = ref('')
const components = Object.assign(...window.platform.apps.flatMap(v => v.components)) || {}
const dataReport = ref({})
const template = ref<TemplateLayout>({ nodes: [] })
const dataReportId = ref('')
const templateName = ref('')
const dqcReportId = ref('')
const tab = ref(route?.query.tab || 'theme')
const zoom = ref(+localStorage.zoom || 1)
const blueprint = ref(localStorage.blueprint !== 'false')
watch(zoom, () => (localStorage.zoom = zoom.value))
watch(blueprint, () => (localStorage.blueprint = blueprint.value))
// @ts-expect-error global $root.profile
const profile = $root.profile
// Composables
function onStoreChange(store: any) {
  idb.set(templateName.value, { undo: JSON.parse(JSON.stringify(store.undo)) })
}
const { getComponentTag } = useComponents()
const { store, undo, redo } = useStore(template, onStoreChange)
const { saveVersion, deleteVersion, restoreVersion, downloadVersion, uploadVersion } = useVersioning(
  store,
  template,
  profile,
)
function onComplete(variables: any) {
  const runId = route.query.runId
  if (runId) return
  updateData(variables)
}
function onError(error: string) {
  errorMessage.value = error
  state.value = 'error'
}
const {
  variables,
  variableInputs,
  missingDependencies,
  inputData,
  dataReports,
  dqcReports,
  templates,
  initBuilder,
  initDataReport,
  updateVariable,
  context,
} = useBuilderContext({ onComplete, onError })
const { getNode, virtualPageAdd } = useLayout(store, template)

const blockActive = computed(() => {
  if (!store.active || !store.active.length) return template.value
  return store.active.reduce((acc, p) => acc?.nodes?.[p], template.value) || template.value
})

const templateCss = ref<string[]>([])
async function updateStyles() {
  if (!template.value.theme?.stylesheet) return
  const stylesheetName = template.value.theme.stylesheet

  const stylesheetsWithName = await stylesheetService.findManyByName({ name: stylesheetName })
  const stylesheet = stylesheetsWithName?.[0]

  if (!template.value.theme.loadedStylesheets) template.value.theme.loadedStylesheets = {}
  template.value.theme.loadedStylesheets[stylesheetName] = stylesheet
  templateCss.value = await getTemplateCss(template)
}
watch(
  () => template.value.theme?.stylesheet,
  async () => {
    await updateStyles()
  },
  { immediate: true },
)

function getDataPath(path: string) {
  if (!dataReport.value) return
  return path.split('.').reduce((acc: any, v) => acc?.[v], dataReport.value)
}
const componentList = computed(() => {
  if (state.value !== 'ready') return []
  function onlyUnique(arr: any[]) {
    return Array.from(new Set(arr))
  }
  const allComponents = Object.entries(components).filter(([componentName, component]) => {
    if (componentName.endsWith('-story') || componentName.endsWith('-error')) return false
    return true
  })
  const customComponents = allComponents.filter(([componentName, component]) => {
    return componentName.startsWith(template.value.theme?.prefix + '-')
  })
  const builderComponents = allComponents.filter(([componentName, component]) => {
    return componentName.startsWith('builder-')
  })
  const _componentList = customComponents.concat(builderComponents)
  if (!blockActive.value.data) {
    return onlyUnique(
      _componentList.map(([k, val]) => k.replace(template.value.theme?.prefix + '-', '').replace('builder-', '')),
    )
  }

  const data = getDataPath(blockActive.value.data)
  return onlyUnique(
    _componentList
      .filter(([componentName, component]) => {
        const viz = getComponentVizType(componentName, component)
        return checkCompatibility(viz, data)
      })
      .map(([k, val]) => k.replace(template.value.theme?.prefix + '-', '').replace('builder-', '')),
  )
})
function onOverflow({
  path,
  isOverflow,
  component,
  end,
  virtual,
}: {
  path: number[]
  isOverflow: boolean
  component: string
  end?: number
}) {
  // if (!template.value.nodes) return
  // return
  if (end) {
    const overflownNode = getNode(path)
    const options = { ...overflownNode?.options, start: end }
    virtualPageAdd(path[0], { virtualPath: path, ...overflownNode, options })
  } else if (isOverflow) {
    const page = template.value.nodes[path[0]]
    const newPage = { ...page, nodes: page.nodes?.filter(d => ['footer', 'header'].includes(d.component)) }
    const hasHeader = newPage.nodes.some(d => d.component === 'header')
    const index = hasHeader ? 1 : 0
    const overflownNode = getNode(path)
    newPage.nodes.splice(index, 0, { virtualPath: path, ...overflownNode })
    if (!template.value.overflowPages) template.value.overflowPages = {}
    template.value.overflowPages[path[0]] = newPage
  } else {
    if (template.value.overflowPages?.[path[0]]) {
      delete template.value.overflowPages[path[0]]
    }
  }
}
const theme = computed(() => {
  if (!template.value.theme) return {}
  // Ignore esling warning for unsued variables, we just declre them so they dont end up in the ...variables
  // eslint-disable-next-line
  const { page_size, page_orientation, globalCss, styles, globalTheme, ...variables } = template.value.theme
  const map = v => {
    if (v instanceof Object) return
    if (!isNaN(+v)) return v * 0.25 + 'rem'
    if (v.startsWith('#'))
      return v
        .slice(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16))
        .join(',')
    return v
  }
  function camelCaseToDash(str) {
    // Note fails if the string starts with a capital letter
    return str.replace(/([A-Z])/g, '-$1').toLowerCase()
  }
  return {
    class: ['pdf', page_size, page_orientation],
    variables: Object.fromEntries(Object.entries(variables).map(([k, v]) => [`--${camelCaseToDash(k)}`, map(v)])),
  }
})

const storeActive = $event => {
  if (tab.value === 'theme') return
  store.active = $event
  nextTick(() => {
    const el = document.querySelector('.ring-yellow-400')
    if (!el) return
    scrollTo({ top: el.getBoundingClientRect().top + window.pageYOffset - 160, behavior: 'smooth' })
  })
}

//! Effects
onMounted(async () => {
  await initBuilder()
  const runId = route.query.runId

  const _stylesheets = await stylesheetService.findMany()
  stylesheets.value = _stylesheets.map(s => s.name)

  const query = route.query
  const metadata = dataReports.value.find(v => v.id === dataReportId.value)
  let runVariables = {}
  if (query.preview) {
    isPreviewMode.value = true
  }
  if (runId) {
    isPreviewMode.value = true
    const run = await get(`data.runs.${runId}`)
    dataReportId.value = run.context.dataReportId
    templateName.value = run.context.template
    // NOTE: why is share run.context.fund_id ?
    const dr = dataReports.value.find(v => v.id === dataReportId.value)
    if (!dr) return
    const runVariablesFromContext = dr.settingVariableParameters.reduce((acc, { name }) => {
      const variable = name === 'lang' ? run.context.language : run.context[name]
      if (variable) {
        return { ...acc, [name]: variable }
      }
      return acc
    }, {})
    initDataReport(dr, runVariablesFromContext)
    zoom.value = 0.8 // for small screen
    const variables = { ...dr?.variables, ...runVariablesFromContext }
    await nextTick()
    await updateData(variables)
  } else {
    dataReportId.value = (route.query.dataReportId as string) || ''
    templateName.value = templates.value.find(v => v === route.query.templateName) || templates.value[0]
    // initVariables(query, metadata?.variables || {})
  }
})

function setDefaultActive(template: any) {
  Object.traverse(
    template,
    () => null,
    (node, path) => {
      if (store.active.length || !node.component || node.component === 'header') return
      store.active = path.filter(p => !['pages', 'nodes'].includes(p)).map(d => parseInt(d))
    },
  )
}
const refreshKey = ref(0)
function refresh() {
  state.value = 'loading'
  template.value = JSON.parse(
    JSON.stringify({ ...template.value, nodes: template.value.nodes.filter(d => !d.isVirtual) }),
  )
  refreshKey.value = refreshKey.value + 1
  // NOTE: refresh is instant in most cases, so we need a timeout so the user notices something happening.
  setTimeout(() => (state.value = 'ready'), 200)
  // updateAll()
}
watch(
  templateName,
  async () => {
    if (!templateName.value) return
    // state.value = 'loading'
    const layout = templateName.value
    updateQuery({ templateName: layout })

    const templatesWithName = await templateService.findManyByName({ name: layout, limit: 50 })
    const firstTemplate = templatesWithName?.[0]

    // todo: choice version here
    const json = migrateLayout(firstTemplate.layout as unknown as TemplateLayout, stylesheets.value)

    // json.layout = migrateLayout(json)
    dataReportId.value = firstTemplate.dataReportId?.toString()
    dqcReportId.value = firstTemplate.dataQualityCheckReportId?.toString() || ''
    const undo = (await idb.get(layout))?.undo || []
    // migrateLayout(data[0].layout)
    store.undo = Object.equal(undo[0], json) ? undo : [json]
    store.undo_skip = true
    store.versions = templatesWithName
    if (tab.value === 'theme' || store.active.length) return
    setDefaultActive(json)
    // state.value = 'ready'
  },
  { immediate: true },
)

async function updateData(variables: Record<string, any>) {
  state.value = 'loading'
  errorMessage.value = ''
  if (!dataReportId.value) return
  dataReport.value = {}
  await nextTick()
  const { data, error } = await dataReportService.run(dataReportId.value, variables, {
    postProcess: 'builder',
    preProcess: true,
  })
  if (error) {
    errorMessage.value = error
    state.value = 'error'
    return
  }
  dataReport.value = data.result
  state.value = 'ready'
}

// Update data when dataReportId or dataReportVariables change
function _onDataReportUpdate() {
  updateQuery({ dataReportId: dataReportId.value })
  template.value.dataReportId = dataReportId.value
  const runId = route.query.runId
  if (runId) return
  const dr = dataReports.value.find(v => v.id === dataReportId.value || v.id.toString() === dataReportId.value)
  if (!dr) return
  initDataReport(dr)
  // await updateData(variables)
}
// Debounce it to prevent double triggering (when multiple dataReportVariables change)
const onDataReportUpdate = _onDataReportUpdate.debounce(100)
watch(
  [dataReportId],
  async () => {
    onDataReportUpdate()
  },
  { immediate: true, deep: true },
)
// NOTE: should that be in builderContext ?
const studioURL = computed(() => {
  if (!dataReportId.value || !dataReports.value) return
  const query = dataReports.value.find(v => v.id === dataReportId.value)?.query
  if (!query) return
  const q =
    query.length > 5000
      ? query
          .replace(/\s+/g, ' ')
          .replace(/\s?{\s?/g, '{')
          .replace(/\s?}\s?/g, '}')
          .replace(/\s?:\s?/g, ':')
      : query
  // @ts-expect-error config is global
  const endpoint = config.graphqlEndpoint
  return `https://studio.apollographql.com/sandbox?endpoint=${encodeURI(
    new URL(endpoint, location.origin).href,
  )}&document=${encodeURI(q)}&variables=${encodeURI(JSON.stringify(variables.value, null, 2))}&headers=${encodeURI(
    JSON.stringify({
      Authorization: `Bearer ${profile.idToken}`,
    }),
  )}`
})
watch(
  tab,
  async () => {
    const tabHeader = tab.value
    updateQuery({ tab: tabHeader })
    if (tab.value === 'theme' && !store.active.length) return
    if (tab.value !== 'theme' && store.active.length) return
    if (tab.value === 'theme') return (store.active = [])
    // TODO make this skip the header

    if (store.active.length) {
      return
    }
    setDefaultActive(template.value)
  },
  { immediate: true },
)

addEventListener('keydown', e => {
  if (e.target.tagName === 'INPUT') return
  if (e.target.tagName === 'TEXTAREA') return
  const fn = () => {
    // if (e.key === 'Backspace') return blockDel()
    // if (e.key === 'ArrowRight') return blockAdd('right')
    // if (e.key === 'ArrowLeft') return blockAdd('left')
    // if (e.key === 'ArrowUp') return blockAdd('top')
    // if (e.key === 'ArrowDown') return blockAdd('bottom')
    if (!(e.metaKey || e.ctrlKey)) return false
    const key = e.key.toLowerCase()
    if (key === '?') return console.log('Help')
    if (key === 'z' && e.shiftKey) return redo()
    if (key === 'z') return undo()
    if (key === 's') return saveVersion(dataReportId.value, profile)
    return false
  }
  if (fn() === false) return
  e.preventDefault()
  e.stopPropagation()
})
</script>
