import { VizType } from '../../../components/common/nx-chart/nx-chart'
import { onlyUnique } from '../lib/utils'

type postFormatFunction = ({
  formattedValue,
  value,
  unit,
  digit,
}: {
  formattedValue: string
  value: number
  unit?: string
  digit?: number
}) => string
export interface ResolvedBuilderOptions {
  viz: VizType
  x: string
  y: string
  unit: string
  digit: number
  limit: number
  other: boolean
  legend: boolean
  filter: string
  rebase: boolean
  lang: string
  title?: string
  subtitle?: string
  disclaimer?: string
  class?: string | string[]
  horizontal?: boolean
  sort?: string
  sortAsc?: boolean
  category?: string
  postFormat?: postFormatFunction
}

interface OptionAttributes {
  type?: 'text' | 'number' | 'checkbox'
  required?: boolean
  placeholder?: string
  class?: string
}

interface BuilderOptionMode {
  label: string
  mode: string
  options: BuilderOptions
}

export interface BuilderOptionArgs {
  data_component: any[]
  options?: ResolvedBuilderOptions
}
export interface BuilderOption {
  label?: string
  select?: (args: BuilderOptionArgs) => string[]
  default?: (args: BuilderOptionArgs) => string | number | boolean | null | undefined
  type?: string
  attrs?: OptionAttributes
  multiple?: {
    values: (args: BuilderOptionArgs) => string[]
    options: BuilderOptions
  }
  modes?: BuilderOptionMode[]
  hide?: (args: BuilderOptionArgs) => boolean
}

// export interface BuilderOptions {
//   x?: BuilderOption
//   y?: BuilderOption
//   unit?: BuilderOption
//   digit?: BuilderOption
//   limit?: BuilderOption
//   other?: BuilderOption
//   legend?: BuilderOption
//   filter?: BuilderOption
//   rebase?: BuilderOption
//   formatTable?: BuilderOption
//   horizontal?: BuilderOption
// }
export type BuilderOptions = Record<string, BuilderOption>

function getXValues({ data_component }: BuilderOptionArgs) {
  return (
    data_component &&
    Object.entries(data_component[0] || {})
      .filter(([k, v]) => typeof v === 'string')
      .map(([k, v]) => k)
  )
}
export const all: BuilderOptions = {
  unit: {
    label: 'Unit',
    default: () => '%',
  },
  digit: {
    label: 'Decimals',
    default: () => 2,
    attrs: {
      type: 'number',
    },
  },
  limit: {
    label: 'Limit',
    default: () => 10,
    attrs: {
      type: 'number',
    },
  },
  other: {
    label: 'Show Other',
    default: () => false,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  legend: {
    label: 'Legend',
    default: ({ data_component }) => {
      return !!data_component[0]?.category
    },
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  filter: {
    label: 'Filter',
    attrs: {
      placeholder: 'YYYY-MM-DD',
    },
  },
  rebase: {
    label: 'Rebase',
    default: () => true,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  y: {
    default: () => 'value',
  },
  x: {
    label: 'By',
    select: getXValues,
    default: ({ data_component }) => {
      const keys = getXValues({ data_component })
      return keys.includes('key') ? 'key' : keys[0]
    },
    hide: () => true,
    attrs: {
      required: true,
    },
  },
  category: {
    label: 'Group by',
    select: getXValues,
    default: ({ data_component }) => {
      const keys = getXValues({ data_component })
      return keys.includes('category') ? 'category' : undefined
    },
    hide: () => true,
  },
  horizontal: {
    label: 'Horizontal',
    default: () => true,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
}

const standard = {
  x: all.x,
  y: all.y,
  category: all.category,
  unit: all.unit,
  digit: all.digit,
  limit: all.limit,
  other: all.other,
}

const legendNames: BuilderOption = {
  label: 'Legend names',
  hide: ({ options }) => !options?.legend,
  multiple: {
    values: ({ options, data_component }) => {
      if (!Array.isArray(data_component)) return []
      if (options?.category) {
        return onlyUnique(data_component.map(d => d[options.category as string]))
      }
      return onlyUnique(data_component.map(d => d[options?.x as string]))
    },
    options: {
      name: {
        label: 'Name',
        default: () => null,
        type: 'translationInput',
      },
    },
  },
}

const legendPosition: BuilderOption = {
  label: 'Legend position',
  hide: ({ options }) => !options?.legend,
  default: () => 'default',
  select: () => ['default', 'top', 'right', 'bottom', 'left', 'floating'],
}

const legendShape: BuilderOption = {
  label: 'Legend shape',
  hide: ({ options }) => !options?.legend,
  default: () => 'line',
  select: () => ['line', 'square', 'circle'],
}

const legendOrientation: BuilderOption = {
  label: 'Legend orientation',
  hide: ({ options }) => !options?.legend,
  default: () => 'vertical',
  select: () => ['vertical', 'horizontal'],
}

export const legendOptions: Record<string, BuilderOption> = {
  legendNames,
  legendPosition,
  legendShape,
  legendOrientation,
}

function legendOptionsFactory(
  defaultShape: 'line' | 'square' | 'circle',
  defaultOrientiation: 'vertical' | 'horizontal',
  defaultPosition: 'default' | 'top' | 'right' | 'bottom' | 'left' | 'floating',
) {
  return {
    ...legendOptions,
    legendShape: {
      ...legendShape,
      default: () => defaultShape,
    },
    legendPosition: {
      ...legendPosition,
      default: () => defaultPosition,
    },
    legendOrientation: {
      ...legendOrientation,
      default: () => defaultOrientiation,
    },
  }
}

export const pie: BuilderOptions = {
  ...standard,
  legend: { ...all.legend, default: () => true },
  ...legendOptionsFactory('square', 'vertical', 'bottom'),
}

export const bar = {
  ...standard,
  horizontal: all.horizontal,
  legend: all.legend,
  ...legendOptionsFactory('square', 'horizontal', 'bottom'),
}
export const table = { ...standard, legend: null }
export const line = {
  ...standard,
  legend: all.legend,
  ...legendOptionsFactory('line', 'horizontal', 'floating'),
  showLegendValues: {
    label: 'Show legend values',
    hide: ({ options }) => !options?.legend,
    default: () => 'last',
    select: () => ['last', 'evolution'],
  },
  unit: { ...all.unit, default: () => null },
  digit: { ...all.digit, default: () => 0 },
  limit: null,
}

export const characteristics = {
  values: {
    label: 'Values',
    multiSelect: {
      values: ({ data_component }) => {
        return data_component && Object.keys(data_component)
      },
    },
  },
}
export const text = {
  values: {
    label: 'Values',
    multiSelect: {
      values: ({ data_component }) => {
        return data_component && Object.keys(data_component)
      },
    },
  },
  text: {
    label: 'Text',
    dataList: ({ context }) => {
      return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
    },
  },
}
